require('./genericEvents');
require('./navbar');
require('./owl-carousel-slider');
require('./nt-industries');
require('./features-overview');
// load home js only for index page
if(document.querySelectorAll('#btn-watch-video').length) {
    require('./home');
}
// load animation js only for pages where animation-wrapper is used
if (document.querySelectorAll('#animation-wrapper').length) {
    require('./animations');
}

// load trigger intercom js only for required pages
if (document.querySelectorAll('#btn-chat-with-us').length) {
    require('./trigger_intercom');
}

window.location.pathname == "/signup" ? require('./form') : '';