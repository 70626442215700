
(function (oneteam, $) {
    oneteam.ntIndustires = (function() {
        return {
            initialize: function() {
                this.industryHoverTab();
            },

            industryHoverTab: function() {
                // initialize active class for first tab on page load
                $(document).ready(function() {
                    $(".nt-industries ul#industry-tabs li a:first").addClass("active");
                    $(".nt-industries #industry-tab-content .tab-pane:first").addClass("show active");
                });

                // toggle active class on each tab hover
                $(".nt-industries ul#industry-tabs li a").each(function() {
                    let reference = $(this).attr('href').replace("#", "");
                    $(this).mouseenter(function() {
                        $(".nt-industries ul#industry-tabs li a").each(function() {
                            $(this).hasClass("active") ? $(this).removeClass("active") : ""
                        });
                        $(this).addClass("active");
                        $(".nt-industries #industry-tab-content .tab-pane").each(function() {
                            $(this).removeClass("show active");

                            $(this).attr('id') == reference ? $(this).addClass("show active") : $(this).removeClass("show active")
                        })
                    });
                    $(this).mouseleave(function() {
                        $(this).removeClass("active");
                    });
                });


                $(".nt-industries ul#industry-tabs").mouseleave(function() {
                    // remove active class from last hovered tab before mouseleave
                    $(".nt-industries #industry-tab-content .tab-pane").removeClass("show active");

                    // set intial state on mouseleave from tabs ui
                    $(".nt-industries ul#industry-tabs li a:first").addClass("active");
                    $(".nt-industries #industry-tab-content .tab-pane:first").addClass("show active");
                });
            },
        }
    })();

    oneteam.loadWhen(oneteam.ntIndustires, function($, window) {
        var path = window.stripWindowsLocationPath();
        return path === "/push-to-talk-app" ||
            path === "/" ||
            path === "/home" ||
            path === "/walkie-talkie-app"
    });

})(oneteam, jQuery);

