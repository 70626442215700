(function (oneteam, $) {
    oneteam.navbar = (function () {

        return {
            /**
             * Initializes the landing page components
             * @return nothing
             */
            initialize: function () {
                this.navbarToggler();
                this.navbarTransition();
            },

            navbarToggler: function() {
                if($(window).width() <= 1024){
                    $('.navbar .navbar-toggler').on('click', function() {
                        $('.navbar-collapse').toggleClass('slide-down');
                    });
                }
            },

            navbarTransition: function() {
                $(window).on("scroll load", function() {
                    let scrollPosition = $(window).scrollTop();
                    if (scrollPosition >= 10) {
                        $("#nt-navbar").removeClass("container");
                        $("#nt-navbar").addClass("on-scroll");
                    }
                    else {
                        $("#nt-navbar").addClass("container");
                        $("#nt-navbar").removeClass("on-scroll");
                    }
                });
            }
        }
    })();

    oneteam.loadWhen(oneteam.navbar, function($, window) {
        var path = window.stripWindowsLocationPath();
        return window;
    });

})(oneteam, jQuery);