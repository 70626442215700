(function(oneteam, $) {

    oneteam.featuresOverview = (function() {
        return {
            /**
             * Initializes the landing page components
             * @return nothing
             */
            initialize: function() {
                this.scrollNtFeatures();
            },

            scrollNtFeatures: function() {
                var sideNavLinks = $(".nt-features-wrapper ul.side-nav li a");

                sideNavLinks.each(function() {
                    $(this).on("click", function() {
                        var $href = $(this).attr('href');
                        var $anchor = $($href).offset();
                        /*section scroll*/
                        $('html, body').animate({
                            scrollTop: $anchor.top - 100
                        }, 50);
                        return false;
                    });
                });

                $(window).on("scroll", function() {
                    var windowScroll = $(window).scrollTop();
                    if (windowScroll >= 466) {
                        $('.nt-features-wrapper .section').each(function(i) {
                            if ($(this).position().top <= windowScroll - 450) {
                                $('.nt-features-wrapper ul.side-nav li a.active').removeClass('active');
                                $('.nt-features-wrapper ul.side-nav li a').eq(i).addClass('active');
                            }
                        });
                    } else {
                        $('.nt-features-wrapper ul.side-nav li a.active').removeClass('active');
                        $('.nt-features-wrapper ul.side-nav li a:first').addClass('active');
                    }
                });
            }
        };
    })();

    oneteam.loadWhen(oneteam.featuresOverview, function($, window) {

        var path = window.stripWindowsLocationPath();

        return path == "/features-overview";
    });
})(oneteam, jQuery);